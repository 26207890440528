.highlight {
    top: 2px;
    left: 2px;
    border-radius: 100px;
    position: absolute;
    width: 156px;
    height: 60px;
    background: linear-gradient(120deg, rgba(32,135,240,1) 0%, rgba(24,115,207,1) 100% );
    box-shadow: inset 0 1px 1px 0px rgba(0, 0, 0, 0.2);
    transition: transform 200ms ease-in-out;
}
