
  
input[type=checkbox] {
    border: 1px solid #dddddd;
    border-radius: 4px;
    background-color: white;
    -webkit-appearance: none;
    width: 15px;
    height: 15px;
    cursor: pointer;
    background: white center center;
}
  
input[type=checkbox]:checked {
  background: transparent url(https://i.imgur.com/jcEenus.png) no-repeat center center
}

input[type=checkbox]:hover:checked {
  background: transparent url(https://i.imgur.com/jcEenus.png) no-repeat center center

}

input[type="checkbox"]:hover  {
  background: transparent url(https://i.imgur.com/O17y09P.png) no-repeat center center
}

  
input[type=radio]:checked {
  background: transparent url(https://i.imgur.com/jcEenus.png) no-repeat center center
}

input[type=radio]:hover:checked {
  background: transparent url(https://i.imgur.com/jcEenus.png) no-repeat center center
}

input[type="radio"]:hover  {
  background: transparent url(https://i.imgur.com/O17y09P.png) no-repeat center center
}

input[type=radio] {
  border: 1px solid #dddddd;
  border-radius: 4px;
  background-color: white;
  -webkit-appearance: none;
  width: 15px;
  height: 15px;
  cursor: pointer;
  background: white center center;
}


input[type=checkbox]:hover input ~ .checkmark {
  border: 1px solid #dddddd;
  border-radius: 4px;
  background-color: #a5a5a5;
  -webkit-appearance: none;
  width: 15px;
  height: 15px;
  cursor: pointer;
  background: #a5a5a5 center center;

  /* box-shadow:inset 0 0 0px 2px white; */

}



  
  