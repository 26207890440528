#banner {
    animation: banneranimation 40s infinite;
    animation-timing-function: linear;
    position: relative;
}

@keyframes banneranimation {
    0% {
        left: 1920px;
    }
    100% {
        left: -500px;
    }
}